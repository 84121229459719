import { Component, Input, ViewChild } from '@angular/core';
import { Member } from '../../../core/models/login/member';
import { SitePaths } from '../../../core/models/sitePaths';
import { SitePathsResponse } from '../../../core/models/sitePathsResponse';
import { AccountService } from '../../../core/services/account.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-goto-admin',
  templateUrl: './goto-admin.component.html',
  styleUrls: ['./goto-admin.component.scss']
})
export class GotoAdminComponent {
  @ViewChild('hrAdminForm') hrAdminFormElement;
  @Input() hrAdminRoute: string;

  member: Member;
  modalID: string;
  sitePaths: SitePaths;
  url: string;

  constructor(private accountService: AccountService, private navigationService: NavigationService) {
    this.member = this.accountService.getCurrentMember();
    let data =  this.navigationService.GetSitePaths().subscribe((data: SitePathsResponse) => {
      this.sitePaths = data.data;
      this.url = this.sitePaths.AdminPath + 'account/fromerportal';
      if (this.hrAdminRoute) {
        this.hrAdminFormElement.nativeElement.action = this.url;
        this.hrAdminFormElement.nativeElement.submit();
      }
    });
   }
}
