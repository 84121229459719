import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { Member } from '../models/login/member'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  member: Member;

  constructor(private accountService: AccountService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //Always pull the current member, they may have been logged out
    this.member = this.accountService.getCurrentMember();

    if (this.member.token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.member.token}`
        }
      });
    }

    return next.handle(request);
  }
}
